import React, { useState } from "react"
import HeaderModal from './headerModal';
import Logo from '../imgs/wz-logo.svg'
import Menu from '../imgs/menu.png'
import Polygon from '../imgs/tangle.png'
import './header.scss'

const HeaderMarket = () => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className='header-container'>
            <div className='menu-row'>
                <div className='logo'><img src={Logo} alt="wz-logo" /></div>
                <div style={{ display: 'none' }} className='menu' onClick={handleOpen}><img src={Menu} alt="menu.png" /></div>
            </div>
           
            <div className='header-inner'>
                <div className='title'>
                    <div>“WE SELL OR ELSE”</div>
                    <div className='name'>David Ogilvy</div>
                </div>
            </div>
            <div className='triangle-container'>
                <div className='triangle'>
                    <img src={Polygon} alt="" />
                </div>

            </div>
            <HeaderModal img={Logo} open={open} handleClose={handleClose} />
        </div>

    )
}

// HeaderMarket.propTypes = {
//     img: PropTypes.string.isRequired,
// };
export default HeaderMarket;
import React, { useState } from "react"
import Box from '@material-ui/core/Box';
import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from "react-icons/fa";
import emailjs from 'emailjs-com';
import Contact from './contact.png'
import "./contactUs.scss";
const AnyReactComponent = ({ text }) => (
    <div className='google-place-name'>
        <div> {text}<br /></div>
        <FaMapMarkerAlt size='25px' color='#fc2c25' />
    </div>
);


const ContactUs = () => {

    const defaultProps = {
        center: {
            lat: -33.870713,
            lng: 151.192475
        },
        zoom: 19
    };


    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [detail, setDetail] = useState('')

    const sentEmail = () => {

        const templateParams = {
            email: email,
            username: name,
            phone: phone,
            query_detail: detail
        };
        // emailjs.send('gmail', 'template_U7kmhQED', templateParams, 'user_EawcONlf9jKq6ddEStFxf')
        emailjs.send('sdfghjkl', 'template_lRwnHSJj', templateParams, 'user_ER3G7mBE1aMBXmdtATe1N')
            .then((response) => {
                alert('邮件已发送')
                console.log('SUCCESS!', response.status, response.text);
            }, (err) => {
                console.log('FAILED...', err);
            });
    }

    return (
        <div className='contact'>

            <div className='address pc-show'>
                2.04/55 Miller St, Pyrmont NSW 2009 / +61(0) 468 323 526 / info@wzm.com.au
            </div>
            <div className='address mobile-show'>
                <div>2.04/55 Miller St, Pyrmont NSW 2009</div>
                <div>+61(0) 468 323 526 / info@wzm.com.au</div>
            </div>

            <div className='contact-form'>
                <div className='left'>
                    <div className='google-map'>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyDVBwjz_OWHEl9xQVnin5q5n4t8TkHBrkM' }}
                            // bootstrapURLKeys={{ key: 'AIzaSyDrxVtm4Ddal9Ou9InxbA7mbgKme41CMSQ' }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                            yesIWantToUseGoogleMapApiInternals
                        >
                            <AnyReactComponent
                                lat={-33.870713}
                                lng={151.192475}
                                text="National Parks Association of NSW"
                            />
                        </GoogleMapReact>
                    </div>
                </div>

                <div className='right'>

                    <form>
                        <div>
                            <input type="text" placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div>
                            <input type="text" placeholder='Email address' value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div>
                            <input type="text" placeholder='Phone' value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div>
                            <textarea type="text" placeholder='Enquiry Details' value={detail} onChange={(e) => setDetail(e.target.value)} />
                        </div>
                        <div className='submit content2' onClick={sentEmail}>Summit</div>
                    </form>
                </div>

            </div>
        </div>

    )
}


export default ContactUs;
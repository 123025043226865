import React from "react"
import Header from '../../componments/headerMarket'
import Footer from '../../componments/footer'
import TitleOne from './projects.svg'
import TitleTwo from './services.svg'
import TitleThree from './contact.svg'
import TitleFour from './clients.svg'
import TitleFive from './partnership.svg'

import ProjectOne from './project1.png'
import ProjectTwo from './project2.png'
import ProjectThree from './project3.png'
import ProjectFour from './project4.png'
import ProjectFive from './project5.png'

import ServiceOne from './service1.svg'
import ServiceTwo from './service2.svg'
import ServiceThree from './service3.svg'
import ServiceFour from './service4.svg'


import ClientOne from './client1.png'
import ClientTwo from './client2.png'
import ClientThree from './client3.png'
import ClientFour from './client4.png'
import ClientFive from './client5.png'
import ClientSix from './client6.png'
import ClientSeven from './client7.png'
import ClientEight from './client8.png'


import PartnerOne from './partner1.png'
import PartnerTwo from './partner2.png'
import PartnerThree from './partner3.png'
import PartnerFour from './partner4.png'
import PartnerFive from './partner5.png'
import PartnerSix from './partner6.png'
import PartnerSeven from './partner7.png'
import ProjectHover1 from './project1.svg'
import ProjectHover2 from './project2.svg'
import ProjectHover3 from './project3.svg'
import ProjectHover4 from './project4.svg'
import ProjectHover5 from './project5.svg'
import Slider from "react-slick";
import Touch from '../../componments/contactus'
import './home.scss'


const ProjectSlick = ({ img, imgHover, defaultStyle, title }) => {
    return (
        <div className='mask-block'>
            <div>
                <img src={img} alt="" />
            </div>
            <div className='mask'>
                <div className={defaultStyle}>
                    <img src={imgHover} alt="" />
                </div>
            </div>
            <div className='mask-title'>
                {title}
            </div>
        </div>
    )
}

const settings = {
    dots: true,
    infinite: true,
    adaptiveHeight: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'mobile-show2'
};

const HomePage = () => {
    return (
        <div>
            <Header />
            <div className='container about white-bg'>
                Expert in digital marketing and Ecommerce, WZ Media is a leading solution-provider who offers integrated marketing solutions and branding services to Australian brands that target the broad and dynamic Chinese market.  <br /> <br />
                Our primary goal is to utilise our strategical thinking and professionalism to deliver value and measurable marketing results for our clients. <br /> <br />
                At WZ Media，We rely on our marketing experts. They are passionate, sales-driven and aim to develop and deepen the digital bond between brands and Chinese consumers. <br /> <br />
                We value strategy, creativity, and quality. We believe in what David Ogilvy once taught us:

                <div className='title'>
                    “We sell or else”.
                </div>
            </div>


            <div className='container projects white-bg'>

                <div className='block-title-center'>
                    <img src={TitleOne} alt="" />
                </div>

                <div >
                    <div className='col-2'>
                        <div className='mask-block'>
                            <div>
                                <img src={ProjectOne} alt="" />
                            </div>
                            <div className='mask'>
                                <div className='mask1'>
                                    <img src={ProjectHover1} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='mask-block'>
                            <div>
                                <img src={ProjectTwo} alt="" />
                            </div>
                            <div className='mask'>
                                <div className='mask2'>
                                    <img src={ProjectHover2} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='mask-block'>
                            <div>
                                <img className='project-3-img' src={ProjectThree} alt="" />
                            </div>
                            <div className='mask'>
                                <div className='mask3'>
                                    <img src={ProjectHover3} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='mask-block'>
                            <div>
                                <img className='project-4-img' src={ProjectFour} alt="" />
                            </div>
                            <div className='mask'>
                                <div className='mask4'>
                                    <img src={ProjectHover4} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='mask-block'>
                            <div>
                                <img className='project-5-img' src={ProjectFive} alt="" />
                            </div>

                            <div className='mask'>
                                <div className='mask5'>
                                    <img src={ProjectHover5} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/* <Slider {...settings} >
                    <ProjectSlick img={ProjectOneM} imgHover={ProjectHover1} defaultStyle='mask1' title={'Sing Tour'} />
                    <ProjectSlick img={ProjectTwoM} imgHover={ProjectHover2} defaultStyle='mask2' />
                    <ProjectSlick img={ProjectThreeM} imgHover={ProjectHover3} defaultStyle='mask3' />
                    <ProjectSlick img={ProjectFourM} imgHover={ProjectHover4} defaultStyle='mask4' />
                    <ProjectSlick img={ProjectFiveM} imgHover={ProjectHover5} defaultStyle='mask5' />

                </Slider> */}


            </div>

            <div className='container services white-bg'>
                <div className='block-title-center'>
                    <img src={TitleTwo} alt="" />
                </div>
                <div className='content'>
                    <div className='right'>
                        <div>
                            <div>
                                <img src={ServiceOne} alt="" />
                                <div>Marketing Strategy</div>
                            </div>
                            <div>
                                <img src={ServiceTwo} alt="" />
                                <div>Event Promotion</div>
                            </div>

                        </div>
                        <div>
                            <div>
                                <img src={ServiceThree} alt="" />
                                <div>Creative Design</div>
                            </div>

                            <div>
                                <img src={ServiceFour} alt="" />
                                <div>Video Editing</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container touch white-bg'>
                <div className='block-title-center'>
                    <img src={TitleThree} alt="" />
                </div>

                <Touch />
            </div>

            <div className='container clients'>
                <div className='block-title'>
                    <img src={TitleFour} alt="" />
                </div>

                <div className='album'>
                    <div>
                        <img src={ClientOne} alt="" />
                    </div>
                    <div>
                        <img src={ClientTwo} alt="" />
                    </div>
                    <div>
                        <img src={ClientThree} alt="" />
                    </div>
                    <div>
                        <img src={ClientFour} alt="" />
                    </div>
                    <div>
                        <img src={ClientFive} alt="" />
                    </div>
                    <div>
                        <img src={ClientSix} alt="" />
                    </div>
                    <div>
                        <img src={ClientSeven} alt="" />
                    </div>
                    <div>
                        <img src={ClientEight} alt="" />
                    </div>
                </div>
            </div>

            <div className='container partnership'>
                <div className='block-title'>
                    <img src={TitleFive} alt="" />
                </div>

                <div className='album'>
                    <div>
                        <img src={PartnerOne} alt="" />
                    </div>
                    <div>
                        <img src={PartnerTwo} alt="" />
                    </div>
                    <div>
                        <img src={PartnerThree} alt="" />
                    </div>
                    <div>
                        <img src={PartnerFour} alt="" />
                    </div>
                    <div>
                        <img src={PartnerFive} alt="" />
                    </div>
                    <div>
                        <img src={PartnerSix} alt="" />
                    </div>
                    <div>
                        <img src={PartnerSeven} alt="" />
                    </div>
                </div>
            </div>





            <Footer />
        </div>
    )
}

export default HomePage
import React from "react"
import { Link } from "react-router-dom";
import Logo from '../imgs/wz-logo.svg'
import "./footer.scss";


const Footer = () => {

    return (
        <div className='footer-container'>
            <div className='footer-inner'>
                <div className='footer-left'>
                    <div className='logo'>
                        <img src={Logo} alt="" />
                    </div>
                    <div className='contact-detail pc-show'>
                        <div>
                            <div>2.04/55 Miller St</div>
                            <div>Pyrmont NSW 2009</div>
                        </div>
                        <div>Phone: 0487 880 136 ｜ Email: info@wzm.com.au</div>
                    </div>
                </div>
                <div className='copyright'>
                    <div>
                        <div>Copyrights © 2020 All Rights Reserved by WZmedia. </div>
                        <div>Terms of Use / Privacy Policy</div>
                    </div>
                </div>
            </div>


        </div>

    )
}


export default Footer;
import React, { useState } from "react"
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import {Link} from "react-router-dom";
import Box from '@material-ui/core/Box';

const HeaderModal = ({ img, handleClose, open }) => {

    const [showSubMenu, setShowSubMenu] = useState(false);
    return (
        <Modal
            open={open}
            onClose={handleClose}>
            <div className='modal-container'>
                <Link to='/' className='modal-banner'>
                    <img src={img} alt="banner" />
                </Link>

                <div className='modal-inner'>
                    <div>
                        <div className='row'>
                            <div className='logo'><img src="/img/wz-logo-black.png" alt="" /></div>
                            <div className='close' onClick={handleClose}><img src="/img/icon/close.png" alt="" /></div>
                        </div>
                        <div className='menu-list container'>
                            <Box className='left'>
                                <Link className='menu-item' to="/projects">Projects</Link>
                                <div className='menu-item' onClick={() => setShowSubMenu(true)}>Service <div><img className='arrow' src="/img/icon/arrow-right.png" alt="" /></div></div>
                                <Link className='menu-item' to="/about">About</Link>
                                <Link className='menu-item no-margin' to="/contact">Contact</Link>

                            </Box>
                            <Box className='right' display={showSubMenu ? 'flex' : 'none'}>
                                <div className='service-item'>Marketing Strategy</div>
                                <div className='service-item'>Event Promotion</div>
                                <div className='service-item'>Creative Design</div>
                                <div className='subitem'>- Graphic Design</div>
                                <div className='subitem'>- packaging Design</div>
                                <div className='service-item'>Video</div>
                            </Box>
                        </div>
                    </div>


                    <div className='modal-btn' onClick={handleClose}>My WZ</div>
                </div>

            </div>
        </Modal>

    )
}

HeaderModal.propTypes = {
    img: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
export default HeaderModal;